.doggo-sidebar {
    position: fixed;
    top: 0;
    right: -33%; /* Off-screen initially */
    bottom: 0;
    width: 33%; /* 1/3 of screen width */
    background: #DD66C4;
    background: linear-gradient(135deg, #DD66C4, #FFC6E8);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, right 0.3s ease; /* Smooth transitions */
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .doggo-sidebar.open {
    right: 0; /* Slide in from the right when open */
  }
  
  .sidebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
  }
  
  .close-button {
    background-color: #FFFFFF; /* White for contrast */
    color: #DD66C4; /* Match the lighter color of the gradient */
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    align-self: flex-end;
  }
  
  .close-button:hover {
    background-color: #FFC6E8; /* Lighter shade of the gradient on hover */
    color: #DD66C4; /* Keep text color consistent */
  }
  
  .sidebar-inner-content {
    flex: 1;
  }
  
  .dog-image {
    width: 100%; /* Make the image width 100% of the container */
    max-height: 300px; /* Set the maximum height to 300px */
    object-fit: contain; /* Ensure the image maintains its aspect ratio */
    margin-bottom: 20px; /* Add some space below the image */
  }
  
  .generate-button, .switch-button {
    background-color: #FFFFFF; /* White for contrast */
    color: #DD66C4; /* Match the lighter color of the gradient */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px;
  }
  
  .generate-button:hover {
    background-color: #FFC6E8; /* Lighter shade of the gradient on hover */
    color: #DD66C4; /* Keep text color consistent */
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    z-index: 999; /* Ensure it's above other content but below the sidebar */
  }
  
  @media (max-width: 768px) {
    .doggo-sidebar {
      width: 100%; /* Full width on smaller screens */
      right: -100%; /* Off-screen initially */
    }
    .doggo-sidebar.open {
      right: 0; /* Slide in from the right when open */
    }
  }
  
  /* HTML: <div class="loader"></div> */
  .loader-dog {
    width: calc(6*30px);
    height: 50px;
    display: flex;
    color: rgb(40, 34, 34);
    filter: drop-shadow(30px 25px 0 currentColor) drop-shadow(60px 0 0 currentColor) drop-shadow(120px 0 0 currentColor);
    clip-path: inset(0 100% 0 0);
    animation: l12 2s infinite steps(7);
  }
  .loader-dog:before {
    content: "";
    width: 30px;
    height: 25px;
    --c:no-repeat radial-gradient(farthest-side,currentColor 92%,#0000);
    background: 
      var(--c) left /70% 70%,
      var(--c) right/20% 20%,
      var(--c) top    0 right 15%/20% 20%,
      var(--c) bottom 0 right 15%/20% 20%;
  }
  @keyframes l12 {
    100% {clip-path: inset(0 -30px 0 0)}
  }

  @media (max-width: 768px) {
    .doggo-sidebar {
      width: 50%; /* 50% of screen width on smaller screens */
      right: -50%; /* Off-screen initially */
    }
    .doggo-sidebar.open {
      right: 0; /* Slide in from the right when open */
    }
    .close-button, .generate-button, .switch-button {
        padding: 8px 16px; /* Adjust padding for smaller buttons */
        font-size: 14px; /* Decrease font size */
      }
  }

  
  @media (max-width: 480px) {
    .doggo-sidebar {
      width: 75%; /* 50% of screen width on smaller screens */
      right: -100%; /* Off-screen initially */
    }
    .doggo-sidebar.open {
      right: 0; /* Slide in from the right when open */
    }
  }
