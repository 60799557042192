
body {
    background: rgb(238, 174, 202);
    background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
    margin: 0;
    font-family: "Playfair Display", cursive, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.compliment-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  background: rgb(238, 174, 202);
  background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
  color: #000; 
}

.compliment-display h2 {
  font-size: 40px;
  margin-bottom: 10px;
}

.compliment-display p {
  font-size: 28px;
  line-height: 1.6;
}

.compliment-display img {
  width: 300px;
  height: auto;
  margin-top: 20px;
}

.compliment-display button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff69b4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  color:#000;
  background:
   radial-gradient(circle at 60% 65%, currentColor 62%, #0000 65%) top left,
   radial-gradient(circle at 40% 65%, currentColor 62%, #0000 65%) top right,
   linear-gradient(to bottom left, currentColor 42%,#0000 43%) bottom left ,
   linear-gradient(to bottom right,currentColor 42%,#0000 43%) bottom right;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  position: relative;
}
.loader:after {
  content: "";
  position: absolute;
  inset: 0;
  background: inherit;
  opacity: 0.4;
  animation: l3 1s infinite;
}
@keyframes l3 {
  to {transform:scale(1.8);opacity:0}
}

@media (max-width: 545px) {
  .compliment-display p {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .compliment-display p {
    font-size: 18px;
  }
}


.card {
  width: 300px;
  height: 400px;
  perspective: 1000px;
  cursor: pointer;
  position: relative;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card:hover .card-inner,
.card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-front {
  z-index: 2;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.card-back {
  transform: rotateY(180deg);
  background: rgb(240, 127, 176);
  background: radial-gradient(circle,  rgba(23, 119, 232, 1) 12%, rgba(240, 127, 176, 1) 100% );
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}